<template>
  <div class="load-more" :class="extraClass">
    <a @click="$emit('loadMore')" href="#" class="load-more__link">{{
      $t("general.load_more")
    }}</a>
  </div>
</template>
<script>
export default {
  props: {
    align: {
      type: String,
      default: "center"
    }
  },
  computed: {
    extraClass() {
      return this.align === "left" ? "text-left" : "text-center";
    }
  }
};
</script>
<style lang="scss">
.load-more {
  .load-more__link {
    color: #0043ac;
    text-decoration: underline;
    font-size: 14px;
    line-height: 16px;
  }
}
</style>
