import { render, staticRenderFns } from "./LoadMore.vue?vue&type=template&id=fde53274"
import script from "./LoadMore.vue?vue&type=script&lang=js"
export * from "./LoadMore.vue?vue&type=script&lang=js"
import style0 from "./LoadMore.vue?vue&type=style&index=0&id=fde53274&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports